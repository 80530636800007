import React from 'react';
import { useTranslation } from 'react-i18next';

import { Label, Text } from '../Text';
import TextInputField from '../TextInputField';
import { Form, Upload } from '../../types';
import RadioGroupField from '../RadioGroupField';
import FileUpload from '../FileUpload';
import CheckboxField from '../CheckboxField';
import { Wrapper } from '../styles';
import DatePickerField from '../DatePickerField';

interface IProps {
    errors: Form;
    handleFileUpload: (key: string, file: Upload) => Upload[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    watch: any;
}

const ClaimInformation: React.FC<IProps> = ({
    register,
    errors,
    control,
    watch,
    handleFileUpload,
}) => {
    const { t } = useTranslation();

    const registerWithErrors = { register, errors };

    const watchIsPregnancy = watch('isPregnancy', 'no');
    const watchIsAccident = watch('isAccident', 'no');
    const watchIsCoop = watch('coopWorkPlacement');
    const watchIsSchoolProgram = watch('schoolProgram');

    return (
        <>
            <Label>{t('claimInformation.title')}</Label>
            <Text>{t('claimInformation.text1')}</Text>
            <Text>{t('claimInformation.text2')}</Text>
            <TextInputField
                name="reasonForClaim"
                label={t('claimInformation.reasonForClaim')}
                {...registerWithErrors}
            />
            <TextInputField
                name="amountClaimed"
                label={t('claimInformation.amountClaimed')}
                inputMode="decimal"
                {...registerWithErrors}
            />

            <DatePickerField
                name="dateFirstDiagnosed"
                control={control}
                label={t('claimInformation.dateFirstDiagnosed')}
                {...registerWithErrors}
            />

            <Wrapper padding="20px">
                <Label marginBottom={'8px'}>{t('claimInformation.isPregnancy')}</Label>

                <RadioGroupField
                    name={'isPregnancy'}
                    row
                    options={[
                        { label: t('global.no'), value: 'no' },
                        { label: t('global.yes'), value: 'yes' },
                    ]}
                    containerStyle={{ marginLeft: '10px', marginBottom: '0px' }}
                    defaultValue={'no'}
                    control={control}
                />

                {watchIsPregnancy === 'yes' && (
                    <>
                        <DatePickerField
                            name="pregnancyDueDate"
                            control={control}
                            label={t('claimInformation.pregnancyDueDate')}
                            containerStyles={{ marginBottom: '0' }}
                            {...registerWithErrors}
                        />
                    </>
                )}
            </Wrapper>
            <Wrapper padding="20px">
                <Label marginBottom={'8px'}>{t('claimInformation.isAccident')}</Label>

                <RadioGroupField
                    name={'isAccident'}
                    row
                    defaultValue={'no'}
                    options={[
                        { label: t('global.no'), value: 'no' },
                        { label: t('global.yes'), value: 'yes' },
                    ]}
                    containerStyle={{ marginLeft: '10px', marginBottom: '0px' }}
                    control={control}
                />

                {watchIsAccident === 'yes' && (
                    <>
                        <DatePickerField
                            name="accidentDate"
                            label={t('claimInformation.accidentDate')}
                            control={control}
                            {...registerWithErrors}
                        />
                        <TextInputField
                            name="accidentExplanation"
                            label={t('claimInformation.accidentExplanation')}
                            required={true}
                            containerStyles={{ marginBottom: '0' }}
                            {...registerWithErrors}
                        />
                    </>
                )}
            </Wrapper>

            <Label>{t('claimInformation.claimRelated')}</Label>
            <CheckboxField
                name={'coopWorkPlacement'}
                label={t('claimInformation.coopWorkPlacement')}
                control={control}
            />
            {watchIsCoop === true && (
                <>
                    <FileUpload
                        name="coopAttachments"
                        label={t('claimInformation.coopAttachmentButton')}
                        ref={register({ required: true })}
                        // errors={errors}
                        containerStyles={{
                            marginLeft: '20px',
                            marginTop: '20px',
                            width: 'fit-content',
                            padding: '7px 80px',
                        }}
                        onFileAttached={handleFileUpload}
                    />
                </>
            )}
            <CheckboxField
                name={'schoolProgram'}
                label={t('claimInformation.schoolProgram')}
                control={control}
            />
            {watchIsSchoolProgram === true && (
                <>
                    <FileUpload
                        name="schoolAttachments"
                        label={t('claimInformation.schoolAttachmentButton')}
                        ref={register({ required: true })}
                        // errors={errors}
                        onFileAttached={handleFileUpload}
                        containerStyles={{
                            marginLeft: '20px',
                            marginTop: '20px',
                            width: 'fit-content',
                            padding: '7px 80px',
                        }}
                    />
                </>
            )}
        </>
    );
};

export default ClaimInformation;
