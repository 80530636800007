import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { Label, Text } from '../Text';
import FileUpload from '../FileUpload';
import { Form, Upload } from '../../types';

interface IProps {
    errors: Form;
    handleFileUpload: (key: string, file: Upload) => Upload[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
}

const PhysiciansAccountRecord = ({ register, errors, handleFileUpload }: IProps) => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <Label>{t('physiciansAccountRecord.title')}</Label>
            <Text>{t('physiciansAccountRecord.text')}</Text>
            <FileUpload
                name="physicianRecordAttachments"
                label={t('physiciansAccountRecord.attachmentButton')}
                ref={register({ required: true })}
                // errors={errors}
                onFileAttached={handleFileUpload}
                containerStyles={
                    !isMobile ? { width: 'fit-content', padding: '7px 80px' } : undefined
                }
            />
        </>
    );
};

export default PhysiciansAccountRecord;
