import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

import { colours } from '../constants/colours';

interface IProps {
    name: string;
    label?: string;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    required?: boolean;
    type?: string;
    register?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    errors?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    customError?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    containerStyles?: React.CSSProperties;
    display?: boolean;
    pattern?: RegExp;
    inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
}

const inputLabelStyle = {
    color: colours.black,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16px',
};

const useStyles = makeStyles({
    input: {
        height: 'auto',
        display: 'flex',
        lineHeight: '31px',
        color: colours.black,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
    },
    disabled: {
        color: colours.black26,
    },
});

const TextInputField: React.FC<IProps> = ({
    name,
    label,
    placeholder,
    value,
    disabled,
    display = false,
    type,
    required = true,
    register,
    errors,
    customError,
    containerStyles = { marginBottom: '30px' },
    inputMode,
    pattern,
}) => {
    const classes = useStyles();

    return (
        <TextField
            name={name}
            label={label}
            value={value}
            type={type}
            InputProps={{
                classes: {
                    input: type !== 'date' ? classes.input : undefined,
                    disabled: classes.disabled,
                },
                disableUnderline: display,
            }}
            style={containerStyles}
            InputLabelProps={{
                style: inputLabelStyle,
                shrink: true,
            }}
            placeholder={placeholder ?? 'Type something...'}
            inputProps={{ pattern, inputMode }}
            disabled={disabled}
            required={required}
            inputRef={register?.({ required, pattern })}
            error={Boolean(customError ?? errors?.[name])}
        />
    );
};

export default TextInputField;
