import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';
import { Text } from '../Text';
import Dropdown from '../Dropdown';

interface IProps {
    handleSchoolSelect: (school: string) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
}

const Intro: React.FC<IProps> = ({ register, control, handleSchoolSelect }) => {
    const { t } = useTranslation();
    const [schools, setSchools] = useState<Array<{ id: number; name: string }>>([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_MORCARE_API}/schools`);
            const schoolsResponse = await response.json();

            // Copied from mobile app
            const listOfSchoolsToDisplay = schoolsResponse.filter(
                (item: { displayInMorcareDropDown: boolean }) => item.displayInMorcareDropDown,
            );
            const listOfSchools = listOfSchoolsToDisplay.map(
                (school: { name: string; id: number; lat: string; lng: string }) => {
                    const name = school.name.replace(' (International Students)', '');
                    const internationalStudents = school.name
                        .toLowerCase()
                        .includes('international students');
                    const id = school.id;
                    return {
                        id,
                        name,
                        internationalStudents,
                        lat: parseFloat(school.lat),
                        lng: parseFloat(school.lng),
                    };
                },
            );

            setSchools(listOfSchools);
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchData();
    }, []);

    const schoolsList = schools.map((school) => {
        return { id: school.id, label: school.name, value: school.name };
    });

    return (
        <Container>
            <Text>{t('intro.line1')}</Text>
            <Text>{t('intro.line2')}</Text>
            <Dropdown
                label={t('Your School')}
                name={'school'}
                options={schoolsList}
                control={control}
                onSelect={handleSchoolSelect}
            />
        </Container>
    );
};

export default Intro;
