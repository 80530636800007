interface SchoolInfo {
    groupNumber: number | string;
    policyNumber: number | string;
}

type Dictionary = { [index: string]: SchoolInfo };

const groupAndPolicyNumbers: Dictionary = {
    'Cambrian International': {
        groupNumber: 514051,
        policyNumber: 100011623,
    },
    'Collège Boréal': {
        groupNumber: 100004,
        policyNumber: 100011038,
    },
    'Dominican University College': {
        groupNumber: 'N/A',
        policyNumber: 'N/A',
    },
    'Durham College': {
        groupNumber: 'N/A',
        policyNumber: 100011018,
    },
    'Fanshawe College': {
        groupNumber: 100002,
        policyNumber: 100011033,
    },
    'Fleming College': {
        groupNumber: 100006,
        policyNumber: 100011392,
    },
    'George Brown - ESL Program': {
        groupNumber: 510000,
        policyNumber: 100011335,
    },
    'George Brown International': {
        groupNumber: 510000,
        policyNumber: 100011335,
    },
    'Humber International': {
        groupNumber: 514561,
        policyNumber: 100011032,
    },
    'Northern College': {
        groupNumber: 'N/A',
        policyNumber: 100012284,
    },
    'Pickering College': {
        groupNumber: 100011,
        policyNumber: 100011740,
    },
    'Seneca College Morcare': {
        groupNumber: 100003,
        policyNumber: 100011031,
    },
};

export default groupAndPolicyNumbers;
