import React from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

import { Direction } from '../../types';
import { colours } from '../../constants/colours';

const useStyles = makeStyles({
    rootForward: {
        backgroundColor: colours.primary,
    },

    rootBackward: {
        backgroundColor: colours.white,
    },

    iconForward: {
        color: colours.white,
    },

    iconBackward: {
        color: colours.black,
    },
});

interface IProps {
    direction?: Direction;
    disabled?: boolean;
    onPress?: () => void;
}

const NavigationButton: React.FC<IProps> = (props: IProps) => {
    const classes = useStyles();

    const getButtonStyles = () => {
        if (props.direction === null || props.direction === Direction.Left) {
            return classes.rootBackward;
        } else {
            return classes.rootForward;
        }
    };

    let content = <ArrowForwardIcon className={classes.iconForward} />;

    if (props.direction === Direction.Left) {
        content = <ArrowBackIcon className={classes.iconBackward} />;
    }

    return (
        <Fab
            style={{ display: 'flex' }}
            className={getButtonStyles()}
            size="small"
            onClick={props.onPress}
        >
            {content}
        </Fab>
    );
};

export default NavigationButton;
