import React from 'react';
import styled from 'styled-components';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Routes from './Routes';
import { colours } from './constants/colours';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: colours.primary,
        },
        secondary: {
            main: colours.secondary,
        },
    },
});

const Layout = styled.div`
    height: 100%;
    width: 100%;
`;

const BackgroundVector5 = styled.div`
    position: absolute;
    z-index: -2;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 1px;
`;

const BackgroundVector4 = styled.div`
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0.5px;
`;

function App() {
    return (
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Layout>
                    <BackgroundVector5>
                        <svg
                            viewBox="0 0 1438 396"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio={'xMinYMax meet'}
                        >
                            <path
                                d="M874.196 235.381C604.7 399.422 213.592 401.572 0 392.075V0H1438V104.124C1330.54 76.6513 1127 81.5 874.196 235.381Z"
                                fill="url(#paint0_linear)"
                                fill-opacity="0.2"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="362.874"
                                    y1="205.195"
                                    x2="1270.02"
                                    y2="-138.805"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#8AA3E1" />
                                    <stop offset="1" stop-color="#B0DDEE" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </BackgroundVector5>
                    <BackgroundVector4>
                        <svg
                            viewBox="0 0 1439 582"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio={'xMinYMax meet'}
                        >
                            <path
                                d="M874.5 347.5C593.984 560.637 213.667 592.5 0 578.5V0.5H1438.5V154C1331 113.5 1180.5 115 874.5 347.5Z"
                                fill="url(#paint0_linear)"
                                fill-opacity="0.2"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear"
                                    x1="363"
                                    y1="303"
                                    x2="1336.5"
                                    y2="52.5"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stop-color="#8AA3E1" />
                                    <stop offset="1" stop-color="#B0DDEE" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </BackgroundVector4>
                    <Routes />
                </Layout>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    );
}

export default App;
