import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from 'react-hook-form';

interface IProps {
    name: string;
    label?: string;
    required?: boolean;
    register?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    control?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    defaultValue?: boolean;
}

const CheckboxField: React.FC<IProps> = ({
    name,
    label,
    control,
    required = false,
    defaultValue = false,
}) => {
    return (
        <Controller
            name={name}
            defaultValue={false}
            control={control}
            rules={{ required }}
            render={({ onChange, value }) => (
                <FormControlLabel
                    label={label}
                    control={
                        <Checkbox
                            checked={value}
                            value={value}
                            onChange={(event) => {
                                onChange(event.target.checked);
                            }}
                        />
                    }
                />
            )}
        />
    );
};

export default CheckboxField;
