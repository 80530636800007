import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';

// import { DevTool } from '@hookform/devtools';

import {
    Container,
    Content,
    Header,
    NavButtonsContainer,
    Justify,
    ProgressBarLabel,
    Main,
} from './styles';
import { H1 } from '../../components/Text';
import NavigationButton from '../../components/NavigationButton';
import { Direction, Form, Upload } from '../../types';
import SolidButton from '../../components/SolidButton';
import groupAndPolicyNumbers from '../../constants/groupAndPolicyNumbers';

// Claim pages
import ClaimIntro from '../../components/Claim0Intro';
import StudentInfo from '../../components/Claim1AStudentInformation';
import DependentInfo from '../../components/Claim1BDependentInformation';
import ClaimInfo from '../../components/Claim2ClaimInformation';
import Section3Intro from '../../components/Claim3IntroAndForward';
import ParamedicalExpenses from '../../components/Claim3ParamericalExpenses';
import PhysiciansAccount from '../../components/Claim4PhysiciansAccountRecord';
import Authorization from '../../components/Claim5Authorization';
import Submission from '../../components/Claim6Submission';
import SubmissionConfirmation from '../../components/Claim7Confirmation';

const MAX_SCREEN = 9;

const ClaimPage = () => {
    const { t } = useTranslation();
    const [displayStep, setDisplayStep] = useState(1);
    const [currentScreen, setCurrentScreen] = useState(0);
    const [form, setForm] = useState<Form>({});
    const {
        register,
        trigger,
        errors,
        getValues,
        setValue,
        control,
        watch,
        reset,
        setError,
        clearErrors,
    } = useForm();

    const handleOnBackPressed = () => {
        if (currentScreen > 0) {
            Object.entries(form).forEach(([key, value]) => {
                setValue(key, value, { shouldValidate: false, shouldDirty: true });
            });

            setCurrentScreen(currentScreen - 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const invoicesExists = (invoices?: object[]) => {
        if (currentScreen === 7 && !invoices) {
            setError('invoicesAttachments', {
                message: t('authorization.requiredInvoiceError'),
                type: 'required',
            });
            return false;
        } else {
            clearErrors('invoicesAttachments');
        }
        return true;
    };

    const handleOnNextPressed = async () => {
        if (currentScreen < MAX_SCREEN) {
            const values = getValues();

            const result = await trigger();
            if (!invoicesExists(values.invoicesAttachments)) {
                return;
            }
            if (result) {
                // Save in state
                setForm((prevState) => {
                    return {
                        ...prevState,
                        ...values,
                    };
                });

                // Reset form
                if (currentScreen === 0) {
                    const school = values.school;
                    reset();
                    handleSchoolSelect(school);
                } else {
                    reset();
                }

                // Go to next screen
                setCurrentScreen(currentScreen + 1);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    };

    const handleSchoolSelect = (school: keyof typeof groupAndPolicyNumbers) => {
        const numbers = groupAndPolicyNumbers[school];
        setValue('policyNumber', numbers.policyNumber);
        setValue('groupNumber', numbers.groupNumber);
    };

    useEffect(() => {
        if (currentScreen) {
            // Update progress bar
            switch (currentScreen) {
                case 0:
                case 1:
                case 2:
                    setDisplayStep(1);
                    break;
                case 3:
                    setDisplayStep(2);
                    break;
                case 4:
                case 5:
                    setDisplayStep(3);
                    setDisplayStep(3);
                    break;
                case 6:
                    setDisplayStep(4);
                    break;
                case 7:
                    setDisplayStep(5);
                    break;
                case 8:
                    setDisplayStep(6);
                    break;
                default:
                    break;
            }
        }

        // Final submit
        if (currentScreen === MAX_SCREEN) {
            const body = {
                ...form,
                attachments: [
                    ...(form.invoicesAttachments ?? []),
                    ...(form.physicianRecordAttachments ?? []),
                    ...(form.coopAttachments ?? []),
                    ...(form.schoolAttachments ?? []),
                ].map((attachment) => attachment.uploadedFile),
            };

            fetch(`${process.env.REACT_APP_CLAIM_URL}/generate`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        }
    }, [currentScreen, form]);

    const displayBody = () => {
        switch (currentScreen) {
            case 0:
                return (
                    <ClaimIntro
                        register={register}
                        control={control}
                        handleSchoolSelect={handleSchoolSelect}
                    />
                );
            case 1:
                return <StudentInfo register={register} errors={errors} control={control} />;
            case 2:
                return (
                    <DependentInfo
                        register={register}
                        errors={errors}
                        control={control}
                        numElements={form?.dependents?.length ?? 0}
                    />
                );
            case 3:
                return (
                    <ClaimInfo
                        register={register}
                        errors={errors}
                        control={control}
                        watch={watch}
                        handleFileUpload={handleFileUpload}
                    />
                );
            case 4:
                return <Section3Intro />;
            case 5:
                return (
                    <ParamedicalExpenses
                        register={register}
                        errors={errors}
                        numElements={form?.paramedicalExpenses?.length ?? 0}
                        control={control}
                    />
                );
            case 6:
                return (
                    <PhysiciansAccount
                        register={register}
                        errors={errors}
                        handleFileUpload={handleFileUpload}
                    />
                );
            case 7:
                return (
                    <Authorization
                        register={register}
                        errors={errors}
                        control={control}
                        handleFileUpload={handleFileUpload}
                    />
                );
            case 8:
                return <Submission />;
            case 9:
                return <SubmissionConfirmation />;
            default:
                return null;
        }
    };

    const handleFileUpload = (key: string, file: Upload) => {
        let files = getValues()[key] ?? [];
        files = [...files, file];
        setValue(key, files);
        return files;
    };

    return (
        <>
            <Helmet>
                <title>{t('global.title')}</title>
            </Helmet>
            <Container>
                <Content>
                    <>
                        <Header>
                            <H1>{t('global.title')}</H1>
                        </Header>

                        {currentScreen > 0 && currentScreen < MAX_SCREEN ? (
                            <>
                                <LinearProgress
                                    variant="determinate"
                                    value={(displayStep / 6) * 100}
                                />
                                <ProgressBarLabel>{`${displayStep} of 6`}</ProgressBarLabel>
                            </>
                        ) : null}
                        <Main>{displayBody()}</Main>
                        <NavButtonsContainer
                            justifyContent={currentScreen === 0 ? Justify.End : undefined}
                        >
                            {currentScreen > 0 && currentScreen < MAX_SCREEN ? (
                                <NavigationButton
                                    direction={Direction.Left}
                                    onPress={handleOnBackPressed}
                                />
                            ) : null}
                            {currentScreen < MAX_SCREEN - 1 ? (
                                <NavigationButton
                                    direction={Direction.Right}
                                    onPress={handleOnNextPressed}
                                />
                            ) : null}
                            {currentScreen === MAX_SCREEN - 1 ? (
                                <SolidButton
                                    label={t('submission.submitButton')}
                                    outline={false}
                                    onClick={handleOnNextPressed}
                                    styles={{ boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.095137)' }}
                                />
                            ) : null}
                        </NavButtonsContainer>
                    </>
                </Content>
            </Container>
            {/* // Uncomment the next line if you want to debug the form */}
            {/* <DevTool control={control} /> */}
        </>
    );
};

export default ClaimPage;
