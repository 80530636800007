import styled from 'styled-components';

import { colours } from '../constants/colours';
import { IStylesProps } from '../types';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${(props: IStylesProps) => props.padding ?? '23px 20px'};
    background-color: ${colours.greyFA};
    border: 1px solid;
    border-color: ${colours.transparent};
    border-radius: 5px;
    margin-bottom: 30px;
`;
