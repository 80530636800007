import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import { Controller } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';

import { IconButton } from '@material-ui/core';

import { Label, H2, Text, StepLabel } from '../Text';
import TextInputField from '../TextInputField';
import { Form, Upload } from '../../types';
import RadioGroupField from '../RadioGroupField';
import FileUpload from '../FileUpload';
import SolidButton from '../SolidButton';

import {
    SignatureContainer,
    SignatureLine,
    SignaturePopupContainer,
    SignaturePopupSubHeding,
    SignaturePopupHeader,
    SignaturePopupHeading,
    SignaturePopupButtonsContainer,
} from './styles';

interface IProps {
    errors: Form;
    handleFileUpload: (key: string, file: Upload) => Upload[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
}

const ClaimAuthorization: React.FC<IProps> = ({ register, errors, control, handleFileUpload }) => {
    const { t } = useTranslation();
    const signatureRef = useRef<SignatureCanvas>(null);
    const [currentSignature, setCurrentSignature] = useState('');
    const registerWithErrors = { register, errors };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleSignatureReset = () => {
        signatureRef.current?.clear();
    };

    const handleSignatureSave = (callback?: (file: string) => void) => async () => {
        const canvas = signatureRef.current?.getCanvas();
        if (canvas) {
            setCurrentSignature(
                canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'),
            );
            const blob = dataURLtoBlob(canvas.toDataURL('image/png'));
            const uploadedFile = await uploadFile(blob);
            if (callback) {
                callback(uploadedFile.file);
            }
        }
    };

    const uploadFile = async (file: Blob) => {
        const data = new FormData();
        data.append('file', file, 'signature.png');
        const response = await fetch(`${process.env.REACT_APP_CLAIM_URL}/upload`, {
            method: 'POST',
            body: data,
        });
        const responseFile = await response.json();
        return responseFile;
    };

    return (
        <>
            <StepLabel>{t('authorization.title')}</StepLabel>
            <H2 marginTop={'5px'} marginBottom={'38px'}>
                {t('authorization.subtitle')}
            </H2>

            <Text>{t('authorization.line1')}</Text>
            <Text>{t('authorization.line2')}</Text>
            <Text>{t('authorization.line3')}</Text>
            <Text>{t('authorization.line4')}</Text>
            <Text marginBottom={'40px'}>{t('authorization.line5')}</Text>

            <Label>{t('authorization.heading1')}</Label>
            <Text>{t('authorization.line6')}</Text>
            <Text marginBottom={'40px'}>{t('authorization.line7')}</Text>

            <Label marginBottom={'23px'}>{t('authorization.paymentMadeHeading')}</Label>
            <RadioGroupField
                name={'paymentMade'}
                row
                options={[
                    {
                        label: t('authorization.paymentMadeStudent'),
                        value: 'student',
                    },
                    {
                        label: t('authorization.paymentMadeHospitalDoctorClinic'),
                        value: 'hospitalDoctorClinic',
                    },
                ]}
                containerStyle={{ marginLeft: '10px', marginBottom: '63px' }}
                defaultValue={'student'}
                control={control}
            />

            <Label>{t('authorization.invoicesHeading')}</Label>
            <FileUpload
                name="invoicesAttachments"
                label={t('authorization.invoicesButton')}
                ref={register({ required: true })}
                // errors={errors}
                onFileAttached={handleFileUpload}
                containerStyles={
                    isMobile
                        ? undefined
                        : {
                              width: 'fit-content',
                              padding: '7px 80px',
                              marginBottom: '20px',
                          }
                }
            />
            <div style={{ marginBottom: '70px' }}>
                {errors.invoicesAttachments ? (
                    <div style={{ color: 'red', textAlign: 'left', marginTop: '5px' }}>
                        {errors.invoicesAttachments.message}
                    </div>
                ) : null}
            </div>

            <TextInputField
                name="signatureName"
                containerStyles={{ marginTop: '20px' }}
                label={t('authorization.signatureName')}
                {...registerWithErrors}
            />
            <TextInputField
                name="signatureStudentId"
                containerStyles={{ marginTop: '30px', marginBottom: '30px' }}
                label={t('authorization.signatureStudentId')}
                {...registerWithErrors}
            />
            <Label>{t('authorization.signatureContainerText')}</Label>

            {isMobile ? (
                <>
                    <Controller
                        control={control}
                        name="signature"
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({ onChange }) => (
                            <Popup
                                modal
                                trigger={
                                    <SolidButton label={t('authorization.signatureButtonMobile')} />
                                }
                                closeOnDocumentClick={false}
                                contentStyle={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '60%',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                }}
                                onClose={handleSignatureSave(onChange)}
                                lockScroll={true}
                            >
                                {(close) => (
                                    <SignaturePopupContainer>
                                        <SignaturePopupHeader>
                                            <SignaturePopupHeading>
                                                {t('authorization.signatureContainerTextMobile')}
                                            </SignaturePopupHeading>
                                            <div>
                                                <IconButton onClick={close}>
                                                    <ClearIcon fontSize="small" />
                                                </IconButton>
                                            </div>
                                        </SignaturePopupHeader>
                                        <SignaturePopupSubHeding>
                                            {t(
                                                'authorization.signatureContainerSubheadingTextMobile',
                                            )}
                                        </SignaturePopupSubHeding>

                                        <SignatureCanvas
                                            ref={signatureRef}
                                            canvasProps={{
                                                className: 'signatureCanvasMobile',
                                            }}
                                        />
                                        <SignaturePopupButtonsContainer>
                                            <SolidButton
                                                label={t(
                                                    'authorization.resetSignatureButtonMobile',
                                                )}
                                                onClick={handleSignatureReset}
                                                styles={{ marginRight: '10px', flex: 0.25 }}
                                                border={false}
                                            />
                                            <SolidButton
                                                label={t('authorization.saveSignatureButtonMobile')}
                                                onClick={close}
                                                styles={{ flex: 0.25 }}
                                            />
                                        </SignaturePopupButtonsContainer>
                                    </SignaturePopupContainer>
                                )}
                            </Popup>
                        )}
                    />
                    {currentSignature && (
                        <SignatureContainer>
                            <img src={currentSignature} width={'100%'} alt="Signature" />
                            <SignatureLine />
                        </SignatureContainer>
                    )}
                </>
            ) : (
                <Controller
                    control={control}
                    name="signature"
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ onChange }) => (
                        <>
                            <SignatureContainer>
                                <SignatureCanvas
                                    ref={signatureRef}
                                    canvasProps={{
                                        className: 'signatureCanvas',
                                    }}
                                />
                                <SignatureLine />
                            </SignatureContainer>
                            <div style={{ display: 'flex', marginTop: '5px', flexShrink: 0 }}>
                                <SolidButton
                                    label={t('authorization.resetSignatureButton')}
                                    onClick={handleSignatureReset}
                                    styles={{ marginRight: '10px', flex: 1 }}
                                />
                                <SolidButton
                                    label={t('authorization.saveSignatureButton')}
                                    onClick={handleSignatureSave(onChange)}
                                    styles={{ flex: 1 }}
                                />
                            </div>
                        </>
                    )}
                />
            )}

            <div style={{ marginBottom: '70px' }}>
                {errors.signature ? (
                    <div style={{ color: 'red', textAlign: 'center', marginTop: '5px' }}>
                        Signature Required
                    </div>
                ) : null}
            </div>

            <Label>{t('authorization.heading2')}</Label>
            <Text>{t('authorization.line8')}</Text>
            <Text>{t('authorization.line9')}</Text>
            <Text>{t('authorization.line10')}</Text>
            <Text>{t('authorization.line11')}</Text>
            <Text>{t('authorization.line12')}</Text>
        </>
    );
};

export default ClaimAuthorization;
