import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '../Text';
import { UnorderedList, ListItem } from './styles';

interface IProps {}

const ClaimInformation: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <Text>{t('instructions3AndForward.text')}</Text>
            <Text marginBottom={'10px'}>{t('instructions3AndForward.exampleTitle')}</Text>
            <UnorderedList>
                <ListItem>{t('instructions3AndForward.listItem1')}</ListItem>
                <ListItem>{t('instructions3AndForward.listItem2')}</ListItem>
                <ListItem>{t('instructions3AndForward.listItem3')}</ListItem>
                <ListItem>{t('instructions3AndForward.listItem4')}</ListItem>
                <ListItem>{t('instructions3AndForward.listItem5')}</ListItem>
                <ListItem>{t('instructions3AndForward.listItem6')}</ListItem>
            </UnorderedList>
        </>
    );
};

export default ClaimInformation;
