import React, { useRef, forwardRef, useState } from 'react';
import styled from 'styled-components';

import SolidButton from './SolidButton';
import { Upload } from '../types';
import { colours } from '../constants/colours';

const ItemContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: ${colours.greyFA};
    border: 1px solid;
    border-color: ${colours.transparent};
    border-radius: 5px;
    padding: 13px;
`;

interface IProps {
    name: string;
    label?: string;
    onFileAttached?: (key: string, file: Upload) => Upload[];
    errors?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    containerStyles?: React.CSSProperties;
}

const FileUpload = forwardRef(
    (
        {
            name,
            label,
            onFileAttached,
            // errors,
            containerStyles,
        }: IProps,
        ref,
    ) => {
        const hiddenFileInput = useRef<HTMLInputElement>(null);
        const [files, setFiles] = useState<Upload[]>([]);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            hiddenFileInput?.current?.click();

            if (ref) {
                if (typeof ref === 'function') {
                    ref(hiddenFileInput.current);
                } else {
                    ref.current = hiddenFileInput.current;
                }
            }
        };

        const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target?.files) {
                const file = event.target.files[0];

                const response = await uploadFile(file);

                if (onFileAttached) {
                    setFiles(
                        onFileAttached(name, {
                            originalFile: file.name,
                            uploadedFile: response.file,
                        }),
                    );
                }
            }
        };

        const uploadFile = async (file: File) => {
            const data = new FormData();
            data.append('file', file);
            const response = await fetch(`${process.env.REACT_APP_CLAIM_URL}/upload`, {
                method: 'POST',
                body: data,
            });
            const returnedFile = await response.json();
            return returnedFile;
        };

        const filesList = files.map((file) => (
            <ItemContainer key={file.uploadedFile}>{file.originalFile}</ItemContainer>
        ));

        return (
            <>
                <SolidButton
                    label={label}
                    onClick={handleClick}
                    styles={containerStyles}
                    hasAddIcon
                />
                <input
                    type="file"
                    name={`${name}Input`}
                    // multiple
                    accept="image/*,.pdf"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: 'none' }}
                />
                {filesList}
            </>
        );
    },
);

export default FileUpload;
