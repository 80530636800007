import styled from 'styled-components';

import { colours } from '../../constants/colours';
import { device } from '../../devices';

export const Container = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
    @media ${device.mobileL} {
        background-color: white;
    } ;
`;

export const Content = styled.section`
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: white;
    overflow-y: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 30px 70px;
    @media ${device.mobileL} {
        flex: 1 0 0;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 20px 20px;
    } ;
`;

export const Header = styled.section`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const ProgressBarLabel = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 8px;
    font-size: 16px;
    color: ${colours.black26};
    line-height: 19px;
    opacity: 0.33;
`;

export const Main = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 30px;
`;

export enum Justify {
    Start = 'flex-start',
    End = 'flex-end',
    SpaceBetween = 'space-between',
}

interface NavButtonProps {
    justifyContent?: string;
}

export const NavButtonsContainer = styled.div`
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: ${(props: NavButtonProps) =>
        props.justifyContent ? props.justifyContent : Justify.SpaceBetween};
`;
