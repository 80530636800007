import styled from 'styled-components';

export const GroupPolicyWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ImageBox = styled.img`
    width: 74px;
    align-self: center;
`;
