import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const UnorderedList = styled.ul`
    margin-top: 0px;
    padding-left: 20px;
`;

export const ListItem = styled.li`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${colours.black26};
`;
