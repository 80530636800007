import React from 'react';
import { useTranslation } from 'react-i18next';

import { Text, H2, StepLabel } from '../Text';

interface IProps {}

const Submission: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <>
            <StepLabel>{t('submission.heading')}</StepLabel>
            <H2 marginTop={'5px'} marginBottom={'31px'}>
                {t('submission.subheading')}
            </H2>

            <Text>{t('submission.line1')}</Text>
        </>
    );
};

export default Submission;
