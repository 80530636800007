import React from 'react';
import { useTranslation } from 'react-i18next';

import { H2 } from '../Text';
import SolidButton from '../SolidButton';

interface IProps {}

const SubmissionConfirmation: React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img
                src={require('../../assets/images/morcare-logo.png')}
                alt="Morcare logo"
                width="250px"
            />
            <H2 marginTop={'0'} marginBottom={'31px'}>
                {t('submissionConfirmation.confirmation')}
            </H2>
            <SolidButton
                label={t('submissionConfirmation.returnHomeButton')}
                outline={false}
                styles={{ boxShadow: '0px 2px 13px rgba(0, 0, 0, 0.095137)', padding: '10px 40px' }}
            />
        </div>
    );
};

export default SubmissionConfirmation;
