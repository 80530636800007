import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

import './i18n';

// CSS reset
import 'sanitize.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/typography.css';

// Fonts
import RobotoLightWoff from './assets/fonts/Roboto-Light.woff';
import RobotoLightWoff2 from './assets/fonts/Roboto-Light.woff2';
import RobotoRegularWoff from './assets/fonts/Roboto-Regular.woff';
import RobotoRegularWoff2 from './assets/fonts/Roboto-Regular.woff2';
import RobotoMediumWoff from './assets/fonts/Roboto-Medium.woff';
import RobotoMediumWoff2 from './assets/fonts/Roboto-Medium.woff2';
import { colours } from './constants/colours';
import { device } from './devices';

const GlobalStyle = createGlobalStyle`
    html {
        height: 100%;
    }

    #root {
        height: 100%;
        width: 100%;
    }

    body {
        height: 100%;
        width: 100%;
        margin: 0;
        display: flex;
        flex: 0 1 auto;
        background: linear-gradient(0deg, rgba(105, 204, 229, 0.2), rgba(105, 204, 229, 0.2)), #FFFFFF;

        font-family: 'Roboto', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media ${device.mobileL} {
            background: white;
        };
    }

    .signatureCanvasMobile {
        display: block;
        border-radius: 5px;
        background-color: ${colours.greyF9};
        flex: 1;
        height: 0;
    }

    .signatureCanvas {
        width: 100%;
        height: 100%;
        backgroundColor: ${colours.greyF9};

    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 300;
        src: local('Roboto'), url(${RobotoLightWoff}) format('woff'), url(${RobotoLightWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 400;
        src: local('Roboto'), url(${RobotoRegularWoff}) format('woff'), url(${RobotoRegularWoff2}) format('woff2');
    }

    @font-face {
        font-family: 'Roboto';
        font-weight: 500;
        src: local('Roboto'), url(${RobotoMediumWoff}) format('woff'), url(${RobotoMediumWoff2}) format('woff2');
    }
`;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <>
                <Router>
                    <Route path="/" component={App} />
                </Router>
                <GlobalStyle />
            </>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
