import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { Label, H2, Text } from '../Text';
import TextInputField from '../TextInputField';
import { Form } from '../../types';
import SolidButton from '../SolidButton';
import { Wrapper } from '../styles';
import DatePickerField from '../DatePickerField';

interface IProps {
    numElements: number;
    errors: Form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
}

const ParamedicalExpenses: React.FC<IProps> = ({ register, errors, numElements, control }) => {
    const { t } = useTranslation();
    const [numExpenses, setNumExpenses] = useState(numElements);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const registerWithErrors = { register, errors };

    const handleAdd = () => {
        setNumExpenses((prevState) => prevState + 1);
    };

    const getParamedicalExpensesForm = () => {
        const expensesForm = [];
        for (let i = 0; i < numExpenses; i++) {
            expensesForm.push(
                <Wrapper padding="23px 20px">
                    <H2 marginTop={'0'} marginBottom={'29px'}>
                        {`${t('paramedicalExpenses.serviceCardTitle')} ${i + 1}`}
                    </H2>
                    <DatePickerField
                        name={`paramedicalExpenses[${i}].dateRendered`}
                        control={control}
                        label={t('paramedicalExpenses.dateRendered')}
                        customError={errors?.paramedicalExpenses?.[i]?.dateRendered}
                        {...registerWithErrors}
                    />
                    <TextInputField
                        name={`paramedicalExpenses[${i}].nature`}
                        label={t('paramedicalExpenses.nature')}
                        {...registerWithErrors}
                        customError={errors?.paramedicalExpenses?.[i]?.nature}
                    />
                    <TextInputField
                        name={`paramedicalExpenses[${i}].description`}
                        label={t('paramedicalExpenses.description')}
                        {...registerWithErrors}
                        customError={errors?.paramedicalExpenses?.[i]?.description}
                    />
                    <TextInputField
                        name={`paramedicalExpenses[${i}].amountCharged`}
                        inputMode="decimal"
                        label={t('paramedicalExpenses.amountCharged')}
                        {...registerWithErrors}
                        customError={errors?.paramedicalExpenses?.[i]?.amountCharged}
                    />
                    <TextInputField
                        name={`paramedicalExpenses[${i}].doctorName`}
                        label={t('paramedicalExpenses.doctorName')}
                        {...registerWithErrors}
                        customError={errors?.paramedicalExpenses?.[i]?.doctorName}
                    />
                    <DatePickerField
                        name={`paramedicalExpenses[${i}].dateFirstConsulted`}
                        control={control}
                        label={t('paramedicalExpenses.dateFirstConsulted')}
                        containerStyles={{ marginBottom: '0px' }}
                        customError={errors?.paramedicalExpenses?.[i]?.dateFirstConsulted}
                        {...registerWithErrors}
                    />
                </Wrapper>,
            );
        }
        return expensesForm;
    };

    return (
        <>
            <Label>{t('paramedicalExpenses.title')}</Label>
            <Text>{t('paramedicalExpenses.text')}</Text>
            {getParamedicalExpensesForm()}
            <SolidButton
                label={t('paramedicalExpenses.serviceButton')}
                onClick={handleAdd}
                styles={!isMobile ? { width: 'fit-content', padding: '7px 80px' } : undefined}
                hasAddIcon
            />
        </>
    );
};

export default ParamedicalExpenses;
