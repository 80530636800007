import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';

interface IProps {
    name: string;
    label: string;
    required?: boolean;
    control?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    register?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    errors?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    customError?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    containerStyles?: React.CSSProperties;
}

const DatePickerField: React.FC<IProps> = ({
    name,
    control,
    label,
    errors,
    customError,
    required = true,
    containerStyles = { marginBottom: '30px' },
}) => {
    return (
        <Controller
            render={({ onChange, value }) => (
                <DatePicker
                    value={value ?? undefined}
                    onChange={(event) => onChange(event?.toDateString())}
                    required={required}
                    error={Boolean(customError ?? errors?.[name])}
                    style={containerStyles}
                    label={label}
                />
            )}
            defaultValue={undefined}
            rules={{ required }}
            control={control}
            name={name}
        />
    );
};

export default DatePickerField;
