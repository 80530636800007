export const colours = {
    primary: '#4BDAFF',
    secondary: '#1ca9f0',
    white: '#ffffff',
    black: '#000000',
    grey: '#ededf7',
    offWhite: '#f4f5f9',
    navy: '#495576',
    greyE5: '#E5E5E5',
    greyFA: '#FAFAFA',
    greyBB: '#BBBBBB',
    greyF9: '#F9F9F9',
    black26: '#262626',
    transparent: 'transparent',
};
