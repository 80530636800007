import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ClaimPage from './pages/claim';

const Routes: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/">
                <ClaimPage />
            </Route>
        </Switch>
    );
};

export default Routes;
