import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const SignatureContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: ${colours.greyFA};
    border: 1px solid;
    border-color: ${colours.transparent};
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
`;

export const SignatureLine = styled.div`
    border: 1px solid ${colours.greyBB};
    margintop: -10px;
`;

export const SignaturePopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 12px 10px 10px;
`;

export const SignaturePopupHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const SignaturePopupHeading = styled.div`
    flex: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
    color: ${colours.black26};
`;

export const SignaturePopupSubHeding = styled.h4`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: ${colours.black26};
    margin-top: 10px;
`;

export const SignaturePopupButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
`;
