import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { Label, H2, Text, StepLabel } from '../Text';
import TextInputField from '../TextInputField';
import { Form } from '../../types';
import SolidButton from '../SolidButton';
import RadioGroupField from '../RadioGroupField';
import { OverEighteenContainer } from './styles';
import { Wrapper } from '../styles';
import DatePickerField from '../DatePickerField';

interface IProps {
    numElements: number;
    errors: Form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
}

const DependentInformation: React.FC<IProps> = ({ register, control, errors, numElements }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { t } = useTranslation();
    const [numDependents, setNumDependents] = useState(numElements);

    const registerWithErrors = { register, errors };

    const handleAdd = () => {
        setNumDependents((prevState) => prevState + 1);
    };

    const getDependentsForm = () => {
        const dependentsForm = [];
        for (let i = 0; i < numDependents; i++) {
            dependentsForm.push(
                <Wrapper>
                    <H2 marginTop={'0'} marginBottom={'29px'}>
                        {`${t('dependentInformation.sectionTitle')} ${i + 1}`}
                    </H2>
                    <TextInputField
                        name={`dependents[${i}].lastName`}
                        label={t('dependentInformation.lastName')}
                        {...registerWithErrors}
                        customError={errors?.dependents?.[i]?.lastName}
                    />
                    <TextInputField
                        name={`dependents[${i}].firstName`}
                        label={t('dependentInformation.firstName')}
                        {...registerWithErrors}
                        customError={errors?.dependents?.[i]?.firstName}
                    />
                    <TextInputField
                        name={`dependents[${i}].gender`}
                        label={t('dependentInformation.gender')}
                        {...registerWithErrors}
                        customError={errors?.dependents?.[i]?.gender}
                    />
                    <DatePickerField
                        name={`dependents[${i}].dateOfBirth`}
                        control={control}
                        label={t('dependentInformation.dateOfBirth')}
                        customError={errors?.dependents?.[i]?.dateOfBirth}
                        {...registerWithErrors}
                    />
                    <TextInputField
                        name={`dependents[${i}].relationship`}
                        label={t('dependentInformation.relationship')}
                        {...registerWithErrors}
                        customError={errors?.dependents?.[i]?.relationship}
                    />
                    <OverEighteenContainer>
                        <Label fontWeight={300}>{t('dependentInformation.radioBoxTitle')}</Label>
                        <Label marginBottom={'8px'}>
                            {t('dependentInformation.fullTimeStudent')}
                        </Label>
                        <RadioGroupField
                            name={`dependents[${i}].fullTimeStudent`}
                            row
                            options={[
                                { label: t('global.no'), value: 'no' },
                                { label: t('global.yes'), value: 'yes' },
                            ]}
                            defaultValue={'no'}
                            control={control}
                            containerStyle={{ marginLeft: '10px', marginBottom: '23px' }}
                        />
                        <Label marginBottom={'8px'}>
                            {t('dependentInformation.handicappedChild')}
                        </Label>

                        <RadioGroupField
                            name={`dependents[${i}].handicappedChild`}
                            row
                            options={[
                                { label: t('global.no'), value: 'no' },
                                { label: t('global.yes'), value: 'yes' },
                            ]}
                            containerStyle={{ marginLeft: '10px' }}
                            control={control}
                            defaultValue={'no'}
                        />
                    </OverEighteenContainer>
                    <TextInputField
                        name={`dependents[${i}].amountClaimed`}
                        label={t('dependentInformation.amountClaimed')}
                        inputMode="decimal"
                        {...registerWithErrors}
                        containerStyles={{ marginBottom: '0px' }}
                        customError={errors?.dependents?.[i]?.amountClaimed}
                    />
                </Wrapper>,
            );
        }
        return dependentsForm;
    };

    return (
        <>
            <StepLabel>{t('dependentInformation.title')}</StepLabel>
            <Text>{t('dependentInformation.text')}</Text>
            {getDependentsForm()}
            <SolidButton
                label={t('dependentInformation.dependentButton')}
                onClick={handleAdd}
                styles={!isMobile ? { width: 'fit-content', padding: '7px 80px' } : undefined}
                hasAddIcon
            />
        </>
    );
};

export default DependentInformation;
