import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import { colours } from '../constants/colours';
import { IStylesProps } from '../types';

const CustomFilledButton = withStyles({
    root: (props: IStylesProps) => ({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 13,
        padding: '7px 18px',
        lineHeight: 1.5,
        border: props.border,
        backgroundColor: colours.primary,
        color: colours.white,
        '&:hover': {
            backgroundColor: colours.greyE5,
        },
    }),
})(Button);

const CustomOutlineButton = withStyles({
    root: (props: IStylesProps) => ({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 13,
        padding: '7px 18px',
        lineHeight: 1.5,
        backgroundColor: colours.white,
        border: props.border ?? `1px solid ${colours.black}`,
        color: colours.black,
        '&:hover': {
            backgroundColor: colours.greyE5,
        },
    }),
})(Button);

interface IProps {
    label?: string;
    hasAddIcon?: boolean;
    outline?: boolean;
    border?: boolean;
    styles?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SolidButton: React.FC<IProps> = ({
    label,
    hasAddIcon,
    outline = true,
    styles,
    border = true,
    onClick,
}) => {
    return (
        <>
            {outline ? (
                <CustomOutlineButton
                    onClick={onClick}
                    startIcon={hasAddIcon && <AddIcon />}
                    style={styles}
                    border={border ? '1px solid' : 'none'}
                >
                    {label}
                </CustomOutlineButton>
            ) : (
                <CustomFilledButton
                    onClick={onClick}
                    startIcon={hasAddIcon && <AddIcon />}
                    style={styles}
                    border={border ? '1px solid' : 'none'}
                >
                    {label}
                </CustomFilledButton>
            )}
        </>
    );
};

export default SolidButton;
