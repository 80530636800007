import React from 'react';
import { useTranslation } from 'react-i18next';

import { H2, StepLabel } from '../Text';
import TextInputField from '../TextInputField';
import { Form } from '../../types';
import RadioGroupField from '../RadioGroupField';
import { ImageBox, GroupPolicyWrapper } from './styles';
import { Wrapper } from '../styles';
import DatePickerField from '../DatePickerField';

interface IProps {
    errors: Form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
}

const StudentInformation: React.FC<IProps> = ({ register, control, errors }) => {
    const { t } = useTranslation();

    const registerWithErrors = { register, errors };

    return (
        <>
            <StepLabel>{t('studentInformation.title')}</StepLabel>
            <H2 marginTop={'5px'} marginBottom={'47px'}>
                {t('studentInformation.section1Title')}
            </H2>
            <TextInputField
                name="lastName"
                label={t('studentInformation.lastName')}
                {...registerWithErrors}
            />
            <TextInputField
                name="firstName"
                label={t('studentInformation.firstName')}
                {...registerWithErrors}
            />

            <Wrapper padding="10xp">
                <GroupPolicyWrapper>
                    <TextInputField
                        name="groupNumber"
                        label={t('studentInformation.groupNumber')}
                        required={false}
                        disabled
                        containerStyles={{ marginBottom: '16px', display: 'flex', flex: 1 }}
                        display
                        {...registerWithErrors}
                    />
                    <ImageBox
                        src={require('../../assets/images/claimSecure.png')}
                        alt="Claim Secure Group logo"
                    />
                </GroupPolicyWrapper>
                <TextInputField
                    name="groupCertificateId"
                    inputMode="numeric"
                    containerStyles={{ marginBottom: '0' }}
                    label={t('studentInformation.groupCertificateId')}
                    {...registerWithErrors}
                />
            </Wrapper>
            <Wrapper padding="10xp">
                <GroupPolicyWrapper>
                    <TextInputField
                        name="policyNumber"
                        label={t('studentInformation.policyNumber')}
                        required={false}
                        containerStyles={{ marginBottom: '16px', display: 'flex', flex: 1 }}
                        display
                        disabled
                        {...registerWithErrors}
                    />
                    <ImageBox
                        src={require('../../assets/images/iaFinancialGroup.png')}
                        alt="IA Financial Group logo"
                    />
                </GroupPolicyWrapper>

                <TextInputField
                    name="policyCertificateId"
                    inputMode="numeric"
                    containerStyles={{ marginBottom: '0' }}
                    label={t('studentInformation.policyCertificateId')}
                    {...registerWithErrors}
                />
            </Wrapper>

            <DatePickerField
                name="dateOfBirth"
                label={t('studentInformation.dateOfBirth')}
                control={control}
                {...registerWithErrors}
            />

            <RadioGroupField
                name={'sex'}
                row
                options={[
                    {
                        label: t('studentInformation.sex.female'),
                        value: 'female',
                    },
                    {
                        label: t('studentInformation.sex.male'),
                        value: 'male',
                    },
                ]}
                defaultValue={'female'}
                control={control}
            />

            <H2 marginTop={'72px'}>{t('studentInformation.section2Title')}</H2>
            <TextInputField
                name="phoneNumber"
                type="tel"
                label={t('studentInformation.phoneNumber')}
                {...registerWithErrors}
            />
            <TextInputField
                name="email"
                type="email"
                label={t('studentInformation.email')}
                {...registerWithErrors}
            />
            <TextInputField
                name="address"
                label={t('studentInformation.address')}
                {...registerWithErrors}
            />
            <TextInputField
                name="apartmentNumber"
                label={t('studentInformation.apartmentNumber')}
                required={false}
                {...registerWithErrors}
            />
            <TextInputField
                name="city"
                label={t('studentInformation.city')}
                {...registerWithErrors}
            />
            <TextInputField
                name="province"
                label={t('studentInformation.province')}
                {...registerWithErrors}
            />
            <TextInputField
                name="postalCode"
                label={t('studentInformation.postalCode')}
                {...registerWithErrors}
            />
        </>
    );
};

export default StudentInformation;
