import styled from 'styled-components';

import { colours } from '../constants/colours';
import { IStylesProps } from '../types';

export const H1 = styled.h1`
    font-family: Roboto;
    font-style: normal;
    margin: 0;
    margin-bottom: 30px;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.115385px;
    color: ${colours.black26};
`;

export const H2 = styled.h2`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: ${colours.black26};
    margin-bottom: ${(props: IStylesProps) => props.marginBottom ?? '20px'};
    margin-top: ${(props: IStylesProps) => props.marginTop ?? '20px'};
`;

export const Text = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: ${colours.black26};
    margin-bottom: ${(props: IStylesProps) => props.marginBottom ?? '30px'};
`;

export const Label = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: ${(props: IStylesProps) => props.fontWeight ?? 500};
    font-size: 14px;
    line-height: 16px;
    color: ${colours.black26};
    margin-bottom: ${(props: IStylesProps) => props.marginBottom ?? '20px'};
    margin-top: ${(props: IStylesProps) => props.marginTop ?? '0'};
`;

export const StepLabel = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${colours.black26};
    margin-bottom: 5px;
`;
