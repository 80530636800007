import React from 'react';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, makeStyles } from '@material-ui/core';
import { Controller } from 'react-hook-form';

import { colours } from '../constants/colours';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        lineHeight: '31px',
    },

    inputLabel: {
        color: colours.black,
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 500,
    },

    selectInputLabel: {
        color: colours.primary,
    },
});

interface IProps {
    name: string;
    label?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: any;
    options: Option[];
    onSelect?: (arg: string) => void;
}

interface Option {
    id: number;
    label: string;
    value: string;
}

const Dropdown: React.FC<IProps> = ({ name, label, control, options, onSelect }) => {
    const classes = useStyles();

    const renderLabel = () => {
        return label ? <InputLabel className={classes.inputLabel}>{label}</InputLabel> : null;
    };

    return (
        <>
            {renderLabel()}
            {options.length > 0 && (
                <Controller
                    name={name}
                    control={control}
                    defaultValue={options[0].value}
                    render={({ value, onChange }) => (
                        <Select
                            className={classes.root}
                            value={value}
                            onChange={(event) => {
                                onChange(event.target.value);
                                if (onSelect) {
                                    onSelect(event.target.value as string);
                                }
                            }}
                        >
                            {options.map((item) => {
                                return (
                                    <MenuItem key={item.id} value={item.value}>
                                        {`${item.label}`}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    )}
                />
            )}
        </>
    );
};

export default Dropdown;
