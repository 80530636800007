import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Controller } from 'react-hook-form';

interface IProps {
    name: string;
    label?: string;
    row?: boolean;
    control?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    options: Option[];
    defaultValue: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    containerStyle?: React.CSSProperties;
}

interface Option {
    value: string;
    label: string;
}

const RadioGroupField: React.FC<IProps> = ({
    name,
    label,
    options,
    row = false,
    control,
    defaultValue,
    containerStyle,
}) => {
    return (
        <>
            {label && <FormLabel>{label}</FormLabel>}
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{ required: true }}
                render={({ onChange, name, value }) => (
                    <RadioGroup
                        row={row}
                        name={name}
                        value={value}
                        onChange={onChange}
                        style={containerStyle}
                    >
                        {options.map((option) => {
                            return (
                                <FormControlLabel
                                    key={option.value}
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label}
                                />
                            );
                        })}
                    </RadioGroup>
                )}
            />
        </>
    );
};

export default RadioGroupField;
