import styled from 'styled-components';

import { colours } from '../../constants/colours';

export const OverEighteenContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    background-color: ${colours.greyFA};
    border: 1px dashed ${colours.greyBB};
    margin-bottom: 30px;
    box-sizing: border-box;
`;
