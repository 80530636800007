export interface INavigation {
    onBackPressed: () => void;
    onNextPressed: () => void;
}

export enum Direction {
    Left,
    Right,
}

export type Form = { [x: string]: any };

export type Upload = { originalFile: string; uploadedFile: string };

export interface IStylesProps {
    marginTop?: string;
    marginBottom?: string;
    fontWeight?: number;
    padding?: string;
    border?: string;
}
